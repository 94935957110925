<template>
  <div
    class="v-application v-application--is-ltr theme--light menuable__content__active h-100"
  >
    <!--begin::SubIn-->
    <b-card class="h-100">
      <div class="row justify-content-between align-self-center mb-3">
        <div>
          <h3 class="text-primary mb-1">Daftar Karyawan</h3>
          <span class="text-muted"
            >Showing {{ parseInt(this.params.offset) + 1 }} to
            {{ parseInt(this.params.offset) + parseInt(params.limit) }} of
            {{ totalCount }} entries</span
          >
        </div>
        <div class="row no-gutters" style="gap: 8px">
          <b-form-group class="mb-0">
            <div class="input-icon">
              <input
                v-model="params.query"
                type="text"
                class="form-control"
                placeholder="Search..."
              />
              <span><i class="flaticon2-search-1 icon-md"></i></span>
            </div>
          </b-form-group>
          <b-form-group class="mb-0">
            <b-form-select
              v-model="params.order"
              :options="optionFilter"
            ></b-form-select>
          </b-form-group>
        </div>
      </div>
      <div
        class="h-100 d-flex pb-8"
        style="flex-direction: column; justify-content: space-between"
      >
        <div class="table-responsive">
          <b-table
            hover
            :items="tableData"
            :fields="fields"
            :per-page="params.limit"
            :current-page="refCurrentPage"
            :busy="isLoading"
            show-empty
          >
            <template #cell(action)="row">
              <b-button
                variant="success"
                class="py-1 px-2 font-weight-bold"
                id="detail"
                title="proses"
                @click="handleView(row.item)"
              >
                <i class="menu-icon flaticon-eye pr-0"></i>
              </b-button>
            </template>
            <template #table-busy>
              <div class="text-center text-muted my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </div>
        <div class="row justify-content-between">
          <div class="col-6 col-md-1">
            <b-form-group class="mb-0">
              <b-form-select
                id="per-page-select"
                v-model="params.limit"
                :options="pageOptions"
                size="sm"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-6 col-md-3">
            <b-pagination
              v-model="refCurrentPage"
              :total-rows="totalCount"
              :per-page="params.limit"
              size="md"
              align="fill"
              class="ml-auto"
              style="padding: 0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </b-card>
    <b-modal
      id="modal-employee"
      size="lg"
      title="Detail Karyawan"
      @hide="resetModal"
    >
      <div class="mb-6 text-center">
        <img
          style="object-fit: scale-down; border-radius: 50%"
          width="150px"
          :src="
            selectedList.photo
              ? selectedList.photo
              : 'https://gardtrax-admin.tdpindonesia.id/dist/img/user-avatar.png'
          "
        />
      </div>
      <div class="d-flex align-items-center justify-content-center mb-6">
        <span style="font-size: 24px">{{ selectedList.name }}</span>
      </div>
      <b-row>
        <b-col class="col-6" style="border-right: 1px solid rgba(0, 0, 0, 0.1)">
          <div class="d-flex align-items-center justify-content-between mb-3">
            <span class="font-weight-bold"> Tanggal Lahir :</span>
            <span class="text-muted">
              {{ selectedList.dob || "No Data" }}
            </span>
          </div>
          <div class="d-flex align-items-center justify-content-between mb-3">
            <span class="font-weight-bold">Jenis Kelamin :</span>
            <span class="text-muted">{{
              selectedList.gender === "L"
                ? "Laki-Laki"
                : selectedList.gender === "P"
                ? "Perempuan"
                : "No Data"
            }}</span>
          </div>
          <div class="d-flex align-items-center justify-content-between mb-3">
            <span class="font-weight-bold">Tempat Lahir :</span>
            <span class="text-muted">{{
              selectedList.birth_place || "No Data"
            }}</span>
          </div>
          <div class="d-flex align-items-center justify-content-between mb-3">
            <span class="font-weight-bold"> Alamat :</span>
            <span class="text-muted">
              {{ selectedList.address || "No Data" }}</span
            >
          </div>
          <div class="d-flex align-items-center justify-content-between mb-3">
            <span class="font-weight-bold">Suku :</span>
            <span class="text-muted">{{
              selectedList.ethnic || "No Data"
            }}</span>
          </div>
          <div class="d-flex align-items-center justify-content-between mb-3">
            <span class="font-weight-bold"> Status Perkawinan :</span>
            <span class="text-muted">
              {{ selectedList.marital_status || "No Data" }}</span
            >
          </div>
          <div class="d-flex align-items-center justify-content-between mb-3">
            <span class="font-weight-bold"> Agama :</span>
            <span class="text-muted">
              {{ selectedList.religion || "No Data" }}
            </span>
          </div>
          <div class="d-flex align-items-center justify-content-between mb-3">
            <span class="font-weight-bold"> Golongan Darah :</span>
            <span class="text-muted">
              {{ selectedList.blood_type || "No Data" }}</span
            >
          </div>
        </b-col>
        <b-col class="col-6">
          <div class="d-flex align-items-center justify-content-between mb-3">
            <span class="font-weight-bold">No E-KTP : </span>
            <span class="text-muted">{{
              selectedList.id_number || "No Data"
            }}</span>
          </div>
          <div class="d-flex align-items-center justify-content-between mb-3">
            <span class="font-weight-bold">E-Mail :</span>
            <span class="text-muted" v-if="selectedList?.user_profile">{{
              selectedList?.user_profile?.email || "No Data"
            }}</span>
          </div>
          <div class="d-flex align-items-center justify-content-between mb-3">
            <span class="font-weight-bold"> No. Kartu Keluarga :</span>
            <span class="text-muted">
              {{ selectedList.family_card_number || "No Data" }}</span
            >
          </div>
          <div class="d-flex align-items-center justify-content-between mb-3">
            <span class="font-weight-bold"> No. Telepon :</span>
            <span class="text-muted">
              {{ selectedList.phone || "No Data" }}</span
            >
          </div>
          <!-- <div class="d-flex align-items-center justify-content-between mb-3">
              <span class="font-weight-bold"> No. Rekening :</span>
              <span class="text-muted"> {{ }}</span>
            </div> -->

          <div class="d-flex align-items-center justify-content-between mb-3">
            <span class="font-weight-bold">Customer :</span>
            <span class="text-muted" v-if="selectedList?.customer_branch">{{
              selectedList.customer_branch.name || "No Data"
            }}</span>
          </div>
          <div class="d-flex align-items-center justify-content-between mb-3">
            <span class="font-weight-bold">No. NPWP :</span>
            <span class="text-muted">{{
              selectedList.npwp_number || "No Data"
            }}</span>
          </div>
          <div class="d-flex align-items-center justify-content-between mb-3">
            <span class="font-weight-bold">NIP : </span>
            <span class="text-muted">{{ selectedList.nip || "No Data" }}</span>
          </div>
          <!-- <div class="d-flex align-items-center justify-content-between mb-3">
              <span class="font-weight-bold">Divisi :</span>
              <span class="text-muted">{{ selectedList.divisi }}</span>
            </div> -->
          <div class="d-flex align-items-center justify-content-between mb-3">
            <span class="font-weight-bold">Jabatan :</span>
            <span class="text-muted" v-if="selectedList?.role">{{
              selectedList.role.name || "No Data"
            }}</span>
          </div>
          <div class="d-flex align-items-center justify-content-between mb-10">
            <span class="font-weight-bold">Shift :</span>
            <span class="text-muted" v-if="selectedList?.shift">{{
              selectedList.shift.name || "No Data"
            }}</span>
          </div>
        </b-col>
      </b-row>
      <hr class="solid" />

      <!-- <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold">Nama Bank :</span>
          <span class="text-muted">Bank Mandiri</span>
        </div> -->

      <!-- <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold"> Area Kerja :</span>
          <span class="text-muted"> {{ selectedList.working_area }}</span>
        </div> -->
      <div class="d-flex align-items-center justify-content-between mb-3">
        <span class="font-weight-bold">No. Kontrak :</span>
        <span class="text-muted">{{
          selectedList.contract_number || "No Data"
        }}</span>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-3">
        <span class="font-weight-bold">Mulai Kontrak :</span>
        <span class="text-muted">{{
          selectedList.contract_start || "No Data"
        }}</span>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-3">
        <span class="font-weight-bold">No. KTA : </span>
        <span class="text-muted">{{
          selectedList.kta_number || "No Data"
        }}</span>
      </div>
      <!-- <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold">Keluar/Resign :</span>
          <span class="text-muted">{{}}</span>
        </div> -->
      <div class="d-flex align-items-center justify-content-between mb-3">
        <span class="font-weight-bold">Status Kontrak :</span>
        <span class="text-muted">{{
          selectedList.contract_status || "No Data"
        }}</span>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-3">
        <span class="font-weight-bold">Akhir Kontrak :</span>
        <span class="text-muted">{{
          selectedList.contract_end || "No Data"
        }}</span>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-3">
        <span class="font-weight-bold"> Jenis KTA :</span>
        <span class="text-muted">
          {{ selectedList.kta_type || "No Data" }}</span
        >
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="secondary"
            size="md"
            @click="$bvModal.hide('modal-employee')"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
    <!--end::SubIn-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import _ from "lodash";
export default {
  name: "ListEmployee",
  data() {
    const { query, sort, order, limit, offset } = this.$route.query;
    return {
      // Table Req
      selected: null,
      isLoading: false,
      currentPage: 1,
      pageOptions: [10, 15, 100],
      optionFilter: [
        { value: "desc", text: "Terbaru" },
        { value: "asc", text: "Terlama" },
      ],
      fields: [
        {
          key: "id",
          label: "Employee ID",
          align: "left",
          sortBy: "",
          sortByFormatted: true,
          filterByFormatted: true,
          // sortable: true,
        },
        {
          key: "name",
          label: "Nama Karyawan",
          align: "left",
          sortBy: "",
          width: "100px",
          // sortable: true,
        },
        {
          key: "nip",
          label: "NIP",
          align: "center",
          width: "10%",
        },
        {
          key: "customer",
          label: "Site Group",
          align: "center",
          width: "10%",
        },
        {
          key: "customer_branch.name",
          label: "Site",
          align: "center",
          width: "10%",
        },
        {
          key: "role.name",
          label: "Jabatan",
          align: "center",
          width: "10%",
        },
        {
          key: "action",
          align: "center",
        },
      ],
      sortBy: "",
      sortDesc: true,
      sortDirection: "desc",
      tableData: [],
      totalCount: 0,
      params: {
        query: query ?? null,
        sort: sort ?? "created_at",
        order: order ?? "desc",
        limit: limit ?? 10,
        offset: offset ?? 0,
      },
      //-----//
      selectedList: {},
    };
  },
  components: {},
  computed: {
    refCurrentPage: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.params.offset = (value - 1) * this.params.limit;
      },
    },
    paramsComp() {
      return JSON.parse(JSON.stringify(this.params));
    },
  },
  mounted() {
    this.getData();
    // this.totalRows = this.tableData.length;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Karyawan" },
    ]);
  },
  created() {},
  methods: {
    navigateUrl() {
      const { query, sort, order, limit, offset } = this.params;
      const routeParams = { query, sort, order, limit, offset };
      if (
        this.$route.name !== "Karyawan" ||
        JSON.stringify(this.$route.query) !== JSON.stringify(routeParams)
      ) {
        this.$router.push({
          name: "Karyawan",
          query: routeParams,
        });
      }
    },
    sortChange(params) {
      Object.entries(params).forEach((item) => {
        if (item[1]) {
          // eslint-disable-next-line
          console.log(item[0], item[1]);
        }
      });
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    },
    handleDelete(item) {
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to deactive this user.`, {
          title: "Confirm Delete",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((trigger) => {
          if (trigger) {
            this.$axios
              .put(
                `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/user/toggle/activate/${item.id}`
              )
              .then(() => {
                this.getData();
              });
          }
        });
    },
    getData: _.debounce(async function () {
      try {
        this.isLoading = true;
        this.navigateUrl();
        await this.$axios
          .get(`${process.env.VUE_APP_BASE_API_DADA}/v1/cms/employees`, {
            params: this.params,
          })
          .then(({ data }) => {
            this.tableData = data.list.map((item) => {
              return {
                ...item,
                customer: "Trans Dana Profitri (TDP)",
              };
            });
            this.totalCount = data.total;
          });
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message, {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    }, 500),
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    handleView(detail) {
      this.selectedList = detail;
      this.$bvModal.show("modal-employee");

      // this.$swal({
      //   title: "View Belom Tersedia",
      //   confirmButtonText: "Ok",
      //   confirmButtonColor: "#27AE60",
      //   heightAuto: false,
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     // this.$swal({
      //     //     text: 'Berhasil!',
      //     //     icon: 'success',
      //     //     heightAuto: false,
      //     // })
      //   }
      // });
    },
    resetModal() {
      this.selectedList = {};
    },
  },
  watch: {
    paramsComp: {
      deep: true,
      handler: _.debounce(function (newValue, oldValue) {
        if (newValue.query !== oldValue.query) {
          this.params.offset = 0;
        }
        this.getData();
      }, 400),
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-status {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  padding: 4px 8px;
  color: #89909a;
  width: fit-content;
  border-radius: 8px;
  font-weight: 500;
  gap: 8px;
  i {
    color: #89909a;
  }
}
</style>
